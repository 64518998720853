<template>
    <div id="box">
        <Header :headerIndex="3"></Header>
        <div class="container">
            <div class="index">
                <div class="part1">
                    <div class="m1340">
                        <div class="title tc">CARD</div>
                        <div class="introduction tc">Card printing instructions. Card printing instructionsCard printing
                            instructions. Card printing instructions</div>
                        <div class="card_box">
                            <div class="fs16 col5 pb5">{{$t('cardamount')}}</div>
                            <div class="flex-box amount_box">
                                <el-input v-model="amount" class="input fs18 mr30"
                                :placeholder="$t('cardamountinput')"></el-input>
                              
                            </div>
                            <div class="fs30  pt30">
                                <div class="fs16 col5 pb5">{{$t('transactionpwd')}}</div>

                                <el-input v-model="password" class="input fs18 mr30" type="password"
                                :placeholder="$t('transactionpwdinput')" ></el-input>
                                
                            </div>
                            <div class="fs30  pt30">
                                <div class="fs16 col5 pb5">{{$t('cardnumber')}}</div>

                                <el-input v-model="num" class="input fs18 mr30" type="number"  @blur="checkNumber"
                                    :placeholder="$t('cardnumberinput')" ></el-input>
                                
                            </div>
                            
                            <div class="flex-box amount_box pt30">
                                <a href="javascript:;" class="btn make mr20" @click="make">{{$t('make')}}</a>
                                <a href="javascript:;" class="btn history" @click="gohistory">{{$t('history')}}</a>
                            </div>

                            <div class="liiustrate fs16 col3">
                                <div class="fs20 col10 fwb">Notice</div>
                                <div class="pt30">
                                    <div class="">

                                    <div class="" v-html="$t('cardnotice')"></div>
                                    </div>                                
                                </div>
                                <!-- <div class="pt30">
                                    2. The invitee clicks on the inviter link, binds a hierarchical relationship with the
                                    inviter after registration, and distributes the card printing amount generated by the
                                    inviter proportionally to the invitee's RP, PP, AP, EP accounts;
                                </div> -->
                            </div>
                            <!-- <div class="fs30 fwb col10 pb30">INVITATION</div>
                            <div class="flex-box fs18 copy_box">
                                 <div class="input_box pl30 mr30">
                                    <span class="cola pr20">Invitation Code</span>
                                    <span class="colb">{{invitecode}}</span>
                                </div> 
                                <div class="input_box plr30 flex-grow-1 flex-box">
                                    <span class="cola pr20">Invitation Link</span>
                                    <span class="colb flex-grow-1 single-line">{{inviteurl}}</span>
                                </div>
                                <a href="javascript:;" class="btn copy mr20" @click="copy">COPY</a>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import CryptoJS from 'crypto-js'
import cookie from 'vue-cookies'
export default {
    data() {
        return {
            amount: '',
            invitecode:'',
            inviteurl:'',
            ismake:false,
            articledetail:'',
            password:'',
            num:1
        };
    },
    created(){
        if (!cookie.get('sma_token')) {
            this.$router.push({ path: '/login', query: { tabindex: 1 } })
            return
        }
        this.config()
    },
    methods: {
        
        checkNumber() {
            // 判断输入的值是否为正整数
            const reg = /^[1-9]\d*$/;
            if (!reg.test(this.num)) {
                // 不是正整数，则将输入框的值设为1
                this.num = 1;
            }
        },
        config(){
            this.$api.config({}).then((res) => {
                if (res.code == 1) {
                    console.log(88)
                    this.articledetail=res.data.card
                } else {
                    // that.$message.error(res.msg);
                    return this.$message({
                        message: res.msg,
                        type: 'error',
                        offset: window.screen.height / 2
                    })
                }
            })
        },
        gohistory(){
            this.$router.push({path:'/history'})
        },
        //加密
        encode (str) {
            return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
                function toSolidBytes(match, p1) {
                    return String.fromCharCode('0x' + p1);
                }));
        },
        // 加密方法
        encrypt(str, key) {
			return CryptoJS.AES.encrypt(str, key).toString()
		},
        make(){
            if(!this.ismake){
                
                if(!this.amount){
                    // return this.$message.error('请填写制卡金额')
                    return this.$message({
                        message: "请填写制卡金额",
                        type: 'error',
                        offset: window.screen.height / 2
                    })
                }
                if(!this.num){
                    // return this.$message.error('请填写制卡金额')
                    return this.$message({
                        message: "请输入制卡数量",
                        type: 'error',
                        offset: window.screen.height / 2
                    })
                }
                if(this.num>10){
                    return this.$message({
                        message: "最大只可批量制卡10个",
                        type: 'error',
                        offset: window.screen.height / 2
                    })
                }
                if(!this.password){
                    // return this.$message.error('请填写制卡金额')
                    return this.$message({
                        message: "请填写交易密码",
                        type: 'error',
                        offset: window.screen.height / 2
                    })
                }

                this.ismake=true
                this.$api.apiDocard({price:this.amount,password:this.password,num:this.num}).then(ret=>{
                    if(ret.code==1){
                        this.amount=""
                        this.num=1

                        this.password=""

                        // this.invitecode=ret.data.invitecode
                        
                        // this.inviteurl='http://localhost:8080/login?'+this.encode('tabindex=0&&docardid='+ret.data.id)
                        // var string=JSON.stringify({tabindex:0,docardid:ret.data.id})
                        // this.inviteurl='https://p.xilukeji.com/sma/sma/dist/index.html#/login?'+this.encrypt(string,"123sd")
                        // this.inviteurl='http://139.196.154.95/sma/dist/index.html#/login?'+this.encrypt(string,"123sd")
                        // this.inviteurl='http://www.smamodle.com/#/login?tabindex=0'+this.encrypt(string,"123sd")
                        // this.inviteurl='http://localhost:8080#/login?'+this.encrypt(string,"123sd")
                        // http://139.196.154.95/sma/dist/#/
                        // http://139.196.154.95/public/qXtJcnfgSi.php/coins/order?ref=addtabs
                        // https://p.xilukeji.com/sma/sma/dist/index.html#/basic_info
                        //更新分享链接
                        // this.$api.apiUpdateDocard({id:ret.data.id,inviteurl:this.inviteurl}).then(ret=>{
                            // this.$message.success('制卡成功')
                            this.$message({
                                message: "制卡成功",
                                type: 'success',
                                offset: window.screen.height / 2
                            })
                            this.ismake=false

                        // })

                    }else{
                        this.ismake=false

                        // this.$message.error(ret.msg)
                        return this.$message({
                        message: ret.msg,
                        type: 'error',
                        offset: window.screen.height / 2
                    })
                    }
                })
            }
            
        },
        copy () {
            //创建input标签
            var input = document.createElement('input')
            //将input的值设置为需要复制的内容
            input.value ='链接:'+this.inviteurl+'\n邀请码：'+ this.invitecode;
            //添加input标签
            document.body.appendChild(input)
            //选中input标签
            input.select()
            //执行复制
            document.execCommand('copy')
            //成功提示信息
            // this.$message.success('success!')
            this.$message({
                message: "复制成功",
                type: 'success',
                offset: window.screen.height / 2
            })
            //移除input标签
            document.body.removeChild(input)
        },
    },
};
</script>
